<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" class="cur-a">个人实名认证</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="centerBox">
          <h3 class="centerTitle">个人实名认证</h3>
          <p class="centerDesc">根据《网络招聘服务管理规定》，网络招聘平台需依法审查招聘信息发布经办人员的身份证明。 为确保您账号的正常使用，请您立即认证!您提交的信息仅作为实名认证使用，我们不会泄露给任何第三方。</p>
          <el-form
              :model="baseform"
              :rules="rules"
              ref="baseform"
              label-width="100px"
          >
            <el-row>
              <h4 class="formTitle">上传证件</h4>
              <div style="clear: both">
                <el-form-item
                    class="uploadFormItem"
                    prop="idCardFront"
                    label-width="0"
                >
                  <el-upload
                      :on-change="handleAvatarSuccessCardFront"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                        :src="
                                baseform.idCardFront ||
                                require('@/assets/renxiang.png')
                              "
                        fit="contain"
                        class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item
                    class="uploadFormItem"
                    prop="idCardBack"
                    label-width="0"
                >
                  <el-upload
                      :on-change="handleAvatarSuccessCardBack"
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                        :src="
                                baseform.idCardBack ||
                                require('@/assets/guohui.png')
                              "
                        fit="contain"
                        class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
              </div>


            </el-row>
            <el-divider></el-divider>
            <el-row>
              <h4 class="formTitle">完善信息</h4>
              <el-form-item label="姓名" prop="userName">
                <el-input
                    v-model="baseform.userName"
                    size="small"
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="idCard">
                <el-input
                    v-model="baseform.idCard"
                    size="small"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <el-input
                    v-model="baseform.mobile"
                    size="small"
                    disabled
                ></el-input>
              </el-form-item>
            </el-row>
          </el-form>
          <div class="btn-box">
            <el-button type="primary" class="bgc-bv" @click="submitForm"
            >提交认证</el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { mapGetters } from "vuex";

import PubSub from "pubsub.js";

export default {
  name: "personalCertification",
  components: {

  },
  data() {
    return {
      baseform: {
        idCardFront: "", // 身份证人像面
        idCardFrontPath: "", // 身份证人像面 key
        idCardBack: "", //身份证国徽面
        idCardBackPath: "", //身份证国徽面 key
        userName: "",
        idCard: "",
        mobile: ""
      },
      rules: {
        idCardFront: [{ required: true, trigger: "change", message: "请上传身份证头像面信息" }],
        idCardBack: [{ required: true, trigger: "change", message: "请上传身份证国徽面信息" }],
        userName: [{ required: true, trigger: "blur", message: "请输入姓名" }],
        idCard: [
          {
            required: true,
            validator: this.$validateIdCard,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
      },
      // 认证信息
      authInfo:{}
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    // console.log(this.userJson)
    this.$nextTick(() => {
      this.baseform.mobile = this.userJson.principal.adminName
    })
  },
  methods: {
    //身份证人像面图片上传
    handleAvatarSuccessCardFront(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      } else if (
          ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              res.raw.type
          ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
            .then((result) => {
              this.baseform.idCardFront = result.data.fileURL;
              this.baseform.idCardFrontPath = result.data.fileKey;
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败",
                });
              }, 300);
            });
      }
    },
    //身份证国徽面图片上传
    handleAvatarSuccessCardBack(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      } else if (
          ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              res.raw.type
          ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
            .then((result) => {
              this.baseform.idCardBack = result.data.fileURL;
              this.baseform.idCardBackPath = result.data.fileKey;
            })
            .catch(() => {
              setTimeout(() => {
                this.$message({
                  type: "warning",
                  message: "上传失败",
                });
              }, 300);
            });
      }
    },
    // 提交认证
    submitForm(){
      const baseform = this.$refs["baseform"];
      baseform.validate((valid) => {
        if (valid) {
          let params = {
            fullname: this.baseform.userName,
            idCard: this.baseform.idCard,
            idCardFace: this.baseform.idCardFrontPath,
            idCardNation: this.baseform.idCardBackPath,
          }
          this.$post("/hr/auth/user", params)
              .then((res) => {
                if (res.status == "0") {
                  this.$message({
                    type: "success",
                    message: "提交成功",
                  });
                  PubSub.publish("authCallback");
                  // 检测有没有进行企业认证  
                  this.checkAuthInfo();
                }
              })
              .catch(() => {
                return;
              });
        }
      })
    },
    // 检测有没有企业认证
    checkAuthInfo(){
        this.$post("/hr/auth/info",{}).then(res=>{
            const {compAuthState}=res.data;
            this.authInfo=res.data;
            // 10 未认证
            if(compAuthState==10){
                this.$confirm('您尚未完成企业认证，请完成企业认证。', '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goCompAuth();
                }).catch(()=>{
                    this.$router.push("/azhr/home");
                });
                // 40 驳回
            }else if(compAuthState==40){
                this.$confirm(`您的企业认证因（${res.data.compAuthReason}）未通过认证，请重新认证。`, '温馨提示', {
                    confirmButtonText: '认证',
                    cancelButtonText: '取消',
                    type: 'warning',
                    showClose:false,
                    closeOnClickModal:false
                }).then(() => {
                    this.goCompAuth();
                }).catch(()=>{
                    this.$router.push("/azhr/home");
                });
            }else{
                this.$router.push("/azhr/home");
            }
        }).catch(e=>{
            console.error("获取认证信息出错",e);
        });
    },
    // 去企业认证
    goCompAuth(){
        const {authInfo}=this;
        this.$router.push({
            path:"/azhr/humanResources/enterpriseCertification",
            query:{
                authInfo
            }
        });
    }
  },
  // beforeRouteLeave: resetKeepAlive,
  watch: {
    // $route: {
      // 深度观察监听
      // deep: true,
    // },
  },
};
</script>
<style lang="less" scoped>
.framePage-body{
  background: transparent;
  position: relative;
}
.centerBox{
  min-width: 665px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
}
.centerTitle{
  text-align: center;
  font-size: 18px;
  color: #333;
  margin-bottom: 12px;
}
.centerDesc{
  text-align: center;
  font-size: 12px;
  color: #999;
  padding-bottom: 14px;
}
.formTitle{
  margin-top: 16px;
  margin-bottom: 30px;
}
/deep/.el-upload{
  border: 0;
  margin: 0 auto;
  width: 184px;
  height: 118px;
}
/deep/.el-form-item{
  margin-left: 30px;
  margin-right: 60px;
}

/deep/.uploadFormItem{
  float: left;
  width: 50%;
  margin: 0 0 10px;
  .el-form-item__error{
    padding-top: 8px;
    margin-left: 80px;
  }
}
.btn-box{
  text-align: center;
  .el-button{
    margin-top: 8px;
    width: 246px;
    padding-top: 0;
    padding-bottom: 0;
    height: 34px;
    line-height: 34px;
  }
}
</style>
